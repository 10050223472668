.navLink {
  a {
    color: white;
    text-decoration: none;
  }
}

.navItem {
  margin-right: 12px;
}

.menuNavLink {
  a {
    color: black;
    text-decoration: none;
  }
}