.progressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.progressContent {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}